.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  width: 40%;
  min-width: 425px;
  margin-left: auto;
  margin-right: auto;
}

.team-name {
  font-weight: bold;
}

.form-group input {
  margin-left: 10px;
  margin-right: 10px;
  /*display: inline-block;*/
}

.row {
  display: inline-block;
  width: 100%;
  /*height: 20px;*/
}

.team {
  float: left;
}
.points {
  float: right;
}

.fake-link {
  cursor:pointer;
  color:blue;
  text-decoration:underline;
}

.left {
  float: left;
}
.right {
  float: right;
}

.median-container {
    display: inline-block;
    width: 100%;
    margin-top: 24px;
    /*height: 20px;*/
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.treevors-container > .row {
  text-align: left;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 425px) {
  .team-name {
    display: none;
  }

  .content {
    width: 100vw;
  }
}
